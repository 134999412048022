/* eslint no-undef: 0 */

/* eslint arrow-parens: 0 */
import Head from 'next/head'
import AboveFooter from 'components/home/AboveFooter'
import Banner3 from 'components/home/Banner3'
import CommentsSection from 'components/home/CommentsSection'
import PopularQuestions from 'components/home/PopularQuestions'
import Content3 from 'components/home/content3'
import Banner30DataSource from 'components/home/data.source'
import { HomeContentDocument } from 'graphql/generated/types'
import ApplicationLayout from 'layouts/application'
import PropTypes from 'prop-types'
import { initializeApollo, addApolloState } from 'util/apolloClient'

const description = () =>
  `A Personal Finance FAQ portal for India, Canada & USA investors. Financial education hub sharing broker
reviews, stock market queries, trading & investment software and apps.`

const Home = (props) => {
  const { isMobile, data } = props

  const {
    all_brokers: allBrokers,
    all_calculators: allCalculators,
    latest_comments: LatestComments,
    all_pages: { data: allPages },
  } = data
  const children = [
    <Banner3
      id="Banner3_0"
      key="Banner3_0"
      dataSource={Banner30DataSource}
      isMobile={isMobile}
    />,
    <PopularQuestions
      id="Feature7_0"
      key="Feature7_0"
      isMobile={isMobile}
      articles={data}
    />,
    <Content3
      id="Content3_0"
      key="Content3_0"
      isMobile={isMobile}
      calculators={allCalculators}
    />,
    <CommentsSection
      id="comments_section"
      key="comments_section"
      isMobile={isMobile}
      comments={LatestComments}
    />,
    <AboveFooter id="Feature7_0" key="Feature7_0" isMobile={isMobile} />,
  ]
  return (
    <ApplicationLayout
      allBrokers={allBrokers.data}
      navCalculators={allCalculators.data}
      allPages={allPages}
    >
      <Head>
        <title>Finance Education Hub: Financial FAQs - Fintrakk</title>
        <meta name="description" content={description()} />
      </Head>
      <div className="templates-wrapper">{children}</div>
    </ApplicationLayout>
  )
}

Home.propTypes = {
  isMobile: PropTypes.bool.isRequired,
}

export async function getStaticProps() {
  const apolloClient = initializeApollo()

  const data = await apolloClient.query({
    query: HomeContentDocument,
  })

  return addApolloState(apolloClient, {
    props: data,
  })
}

export default Home
