import React from 'react'
import { Row, Col } from 'antd'

import QuickLinks from 'components/shared/QuickLinks'
import EmbedWidget from 'components/calculator/EmbedWidget'

import { getChildrenToRender } from 'shared/util'

const dataSource = () => {
  return {
    wrapper: { className: 'home-page-wrapper content3-wrapper' },
    page: { className: 'home-page content3' },
    OverPack: { playScale: 0.3 },
    titleWrapper: {
      className: 'content3-title-wrapper',
      children: [
        {
          name: 'title',
          className: 'content3-title-h1',
          children: 'Wish to Learn Something New Today',
        },
      ],
    },
    secondtitleWrapper: {
      className: 'content3-title2-wrapper',
      children: [
        {
          name: 'title',
          className: 'content3-title-h1',
          children: 'Try & Embed FREE Financial Calculators',
        },
      ],
    },
  }
}

function Feature7(props) {
  const { isMobile, calculators, ...tagProps } = props
  const dataSourceWithBrokers = dataSource()
  const { titleWrapper, secondtitleWrapper } = dataSourceWithBrokers
  const popularPosts = [
    {
      href: 'https://fintrakk.com/how-to-trade-in-international-market-from-india',
      linkText: `Invest in US Stocks`,
    },
    {
      href: 'https://fintrakk.com/best-demat-trading-account-in-india-charges-comparison-review',
      linkText: `Best Trading Account`,
    },
    {
      href: 'https://fintrakk.com/best-discount-brokers-in-india',
      linkText: `Best Discount Brokers`,
    },
    {
      href: 'https://fintrakk.com/5-best-long-term-investment-options-in-india',
      linkText: `Long term Investments`,
    },
    {
      href: 'https://fintrakk.com/option-trading-meaning-types-of-options-examples',
      linkText: `Option Trading`,
    },
  ]

  const financeBlogs = [
    {
      href: 'https://fintrakk.com/basic-financial-terms-accounting-concepts',
      linkText: `Basic Financial Terms`,
    },
    {
      href: 'https://fintrakk.com/how-to-trade-in-international-market-from-india',
      linkText: `Trade in International Market`,
    },
    {
      href: 'https://fintrakk.com/financial-intermediaries-meaning-types-examples-importance',
      linkText: `Financial Intermediaries`,
    },
    {
      href: 'https://fintrakk.com/finance-quotes-best-inspirational-financial-quotes-of-all-time',
      linkText: `Finance Quotes`,
    },
    {
      href: 'https://fintrakk.com/money-quotes-best-top-quotations',
      linkText: `Money Quotes`,
    },
  ]

  const stockMarketBlogs = [
    {
      href: 'https://fintrakk.com/stock-market-basics-for-beginners-terms-concepts',
      linkText: `Stock Market Basics`,
    },
    {
      href: 'https://fintrakk.com/types-of-stocks-different-classification',
      linkText: `Types of Stocks`,
    },
    {
      href: 'https://fintrakk.com/types-of-investors-in-the-stock-market',
      linkText: `Types of Investors`,
    },
    {
      href: 'https://fintrakk.com/types-of-stock-brokers-full-service-vs-discount',
      linkText: `Types of Brokers`,
    },
    {
      href: 'https://fintrakk.com/top-stock-market-training-institutes-india',
      linkText: `Stock Market Institutes`,
    },
  ]

  const investmentBlogs = [
    {
      href: 'https://fintrakk.com/high-return-investments-in-india-best-investment',
      linkText: `High Return Investments`,
    },
    {
      href: 'https://fintrakk.com/financial-ratios-stock-investing-important-basic',
      linkText: `Important Financial Ratios`,
    },
    {
      href: 'https://fintrakk.com/best-trading-platform-in-india-online',
      linkText: `Best Trading Platforms`,
    },
    {
      href: 'https://fintrakk.com/best-savings-bank-account-in-india-interest-rate-features',
      linkText: `Best Savings Bank Account`,
    },
    {
      href: 'https://fintrakk.com/types-of-stock-trading-in-india',
      linkText: `Types of Stock Trading`,
    },
  ]

  const topTen = [
    {
      href: 'https://fintrakk.com/top-10-richest-people-in-the-world',
      linkText: `Richest People in the World`,
    },
    {
      href: 'https://fintrakk.com/top-edtech-companies-in-india-best',
      linkText: `Edtech Companies India`,
    },
    {
      href: 'https://fintrakk.com/top-10-fintech-companies-in-india-most-successful-startups',
      linkText: `Fintech Companies India`,
    },
    {
      href: 'https://fintrakk.com/largest-stock-exchanges-in-the-world-top',
      linkText: `Largest Stock Exchanges`,
    },
    {
      href: 'https://fintrakk.com/largest-financial-centers-world-biggest-cities',
      linkText: `Largest Financial  Centers`,
    },
  ]

  const careerInFinance = [
    {
      href: 'https://fintrakk.com/best-career-options-in-stock-market-india-top-list',
      linkText: `Stock Market Career`,
    },
    {
      href: 'https://fintrakk.com/best-courses-after-12th-commerce',
      linkText: `Best Commerce Courses`,
    },
    {
      href: 'https://fintrakk.com/best-courses-after-12th-career-options-to-look-for',
      linkText: `Creative Career Options`,
    },
    {
      href: 'https://fintrakk.com/cfa-certification-chartered-financial-analyst-exam',
      linkText: `CFA Certification Course`,
    },
    {
      href: 'https://fintrakk.com/how-to-make-money-online-in-india-ways-grow-rich',
      linkText: `Make Money Online`,
    },
  ]

  return (
    <div {...tagProps} {...dataSourceWithBrokers.wrapper}>
      <div {...dataSourceWithBrokers.page}>
        <div {...dataSourceWithBrokers.titleWrapper}>
          {titleWrapper.children.map(getChildrenToRender)}
        </div>
        <Row gutter={[150, 48]}>
          <Col xs={24} lg={8}>
            <QuickLinks title="Popular Posts" linkList={popularPosts} />
          </Col>
          <Col xs={24} lg={8}>
            <QuickLinks title="Finance Blogs" linkList={financeBlogs} />
          </Col>
          <Col xs={24} lg={8}>
            <QuickLinks
              title="Stock Market Blogs"
              linkList={stockMarketBlogs}
            />
          </Col>

          <Col xs={24} lg={8}>
            <QuickLinks title="Investment Blogs" linkList={investmentBlogs} />
          </Col>
          <Col xs={24} lg={8}>
            <QuickLinks title="Top 10" linkList={topTen} />
          </Col>
          <Col xs={24} lg={8}>
            <QuickLinks title="Career in Finance" linkList={careerInFinance} />
          </Col>
        </Row>
        <div {...dataSourceWithBrokers.secondtitleWrapper}>
          {secondtitleWrapper.children.map(getChildrenToRender)}
        </div>
        <Row className="content3-calculators-container">
          <Col xs={24} lg={8}>
            <EmbedWidget calculatorData={calculators.data[3]} />
          </Col>
          <Col xs={24} lg={8}>
            <EmbedWidget calculatorData={calculators.data[1]} />
          </Col>
          <Col xs={24} lg={8}>
            <EmbedWidget calculatorData={calculators.data[2]} />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Feature7
