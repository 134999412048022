import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Col, message, Input } from 'antd'
import Button from 'components/shared/button'
import { CloseOutlined } from '@ant-design/icons'
import CalSettings from 'components/data/calSettings.json'

const { TextArea } = Input

const info = (msg) => {
  message.info(msg)
}

const copyText = (element) => {
  try {
    element.select()
    document.execCommand('copy')
    info('Code has been copied to Clip Board.')
  } catch (err) {
    info('Sorry! Something Went Wrong.')
  }
}

const widgetText = (name, slug) => {
  const { containerStyle } = CalSettings[slug] || {
    containerStyle: {
      width: '297',
      height: '435',
    },
  }

  return `
  <div style="width: ${containerStyle.width}px; margin: auto; text-align: center">
    <iframe title="CRwidget"
      src="https://app.fintrakk.com/widget/${slug}"
      width="${containerStyle.width}px"
      height="${containerStyle.height}px"
      frameBorder="0"
      style="margin-bottom: 16px">
</iframe>
<div> <a href="https://app.fintrakk.com/calculator/${slug}"> <b> ${name} Calculator </b> </a> By Fintrakk </div>
</div>`
}

export default function EmbedPopup({ calculatorData, buttonText, style }) {
  const [visible, setvisible] = useState(false)
  const { name, slug } = calculatorData.attributes || {}
  const handleModal = (bolState) => {
    setvisible(bolState)
  }
  const handleCopy = () => {
    const selectedDom = document.getElementById('widget-text')
    copyText(selectedDom)
    setTimeout(() => setvisible(false), 3000)
  }

  return (
    <>
      <Button
        size="large"
        type="primary"
        style={style}
        onClick={() => handleModal(true)}
      >
        {buttonText}
      </Button>
      <Modal
        open={visible}
        header={null}
        footer={null}
        style={{ align: 'center' }}
        closeIcon={<CloseOutlined onClick={() => handleModal(false)} />}
      >
        <Row
          type="flex"
          style={{
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '22px',
            margin: '13px auto auto auto',
            textAlign: 'center',
          }}
        >
          <Col style={{ width: '100%' }}>
            Copy the HTML Code and Put this Calculator on Your Website
          </Col>
        </Row>
        <Row type="flex" style={{ textAlign: 'center' }}>
          <Col
            style={{
              fontWeight: 'normal',
              fontSize: '14px',
              lineHeight: '22px',
              width: '396px',
              margin: '32px auto auto auto',
            }}
          >
            <TextArea
              rows={8}
              id="widget-text"
              style={{
                border: '0',
                outline: '0',
                fontSize: '14px',
                borderBottom: '2px solid',
                resize: 'none',
              }}
              onClick={(event) => event.target.select()}
              value={widgetText(name, slug)}
            />
          </Col>
        </Row>
        <Row type="flex" style={{ textAlign: 'center' }}>
          <Button
            size="large"
            type="primary"
            style={{
              margin: '2% auto auto 77%',
              position: 'relative',
              height: '40px',
              float: 'right',
              textAlign: 'left',
            }}
            onClick={handleCopy}
          >
            Copy
          </Button>
        </Row>
      </Modal>
    </>
  )
}

EmbedPopup.propTypes = {
  calculatorData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  buttonText: PropTypes.string.isRequired,
  style: PropTypes.shape({
    float: PropTypes.string,
    margin: PropTypes.string,
    position: PropTypes.string,
  }).isRequired,
}
