import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd'
import CalSettings from 'components/data/calSettings.json'
import EmbedPopup from './EmbedPopup'

const WidgetIframe = (props) => {
  const { calculatorData } = props
  const { name, slug } = calculatorData.attributes || {}
  const { containerStyle } = CalSettings[slug] || {
    containerStyle: {
      width: '297',
      height: '435',
    },
  }
  return (
    <div
      style={{
        margin: 'auto',
        textAlign: 'center',
      }}
    >
      <iframe
        title="CRwidget"
        src={`/widget/${slug}`}
        width={`${containerStyle.width}px`}
        height={`${containerStyle.height}px`}
        frameBorder={0}
        style={{ marginBottom: '16px' }}
      />
      <div>
        <a href={`https://app.fintrakk.com/calculator/${slug}`}>
          <b>{`${name} Calculator`}</b>
        </a>
        {` By Fintrakk`}
      </div>
    </div>
  )
}

export default function EmbedWidget(props) {
  const { calculatorData } = props
  return (
    <Row className="page-wrapper">
      <Col md={16} xs={24}>
        <Row className="cal-container">
          <WidgetIframe calculatorData={calculatorData} />
          <EmbedPopup
            calculatorData={calculatorData}
            buttonText="Get Embed Code"
            style={{ margin: '16px auto', position: 'relative' }}
          />
        </Row>
      </Col>
    </Row>
  )
}

WidgetIframe.propTypes = {
  calculatorData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
}

EmbedWidget.propTypes = {
  calculatorData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
}
